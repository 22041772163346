import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Episode from "./episode-container";
import episodes from "./episodes";
import "./App.css";

class Masterclass extends React.Component {
  render() {
    return (
      <>
      <Container fluid className="App">
      <Container className="Main">
        <Row className="align-items-center justify-content-center" id="top">
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <Image src="/img/jurek.png" className="jurek" />
          </Col>
          <Col className="ml-2">
            <h1>Jurek Masterclass</h1>
            <h3>Esittelyteksti</h3>
            <ul>
              {episodes.map((episode, index) => {
                return <li><a href={"#" + index}>{episode.heading} ({episode.length})</a></li>;
              })}
            </ul>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center mt-3 mb-3">
          <Image src="/img/vinyl.png" className="App-logo" />
          <Image src="/img/vinyl.png" className="App-logo" />
          <Image src="/img/vinyl.png" className="App-logo" />
        </Row>
        <Row>
          {episodes.map((episode, index) => {
            return (
              <Episode
                nro={index}
                url={episode.url}
                title={episode.title}
                heading={episode.heading}
                desc={episode.desc}
                length={episode.length}
              />
            );
          })}
        </Row>
      </Container>
      <Container className="Footer">
        <p>Hook Finland Oy © 2021</p>
      </Container>
    </Container>
      </>
    );
  }
}

export default Masterclass;
