import React, { useState, useEffect } from "react";
import Masterclass from "./Masterclass";
import Password from "./Password";
import "./App.css";

function App() {
  const [password, setPassword] = useState("");
  const auth = localStorage.getItem("auth");

  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth !== "authenticated") {
      var attempt = prompt("Salasana");
      if (attempt !== "Kissa") {alert("Väärä salasana");}
      setPassword(attempt);
    }
  }, []);

  if (password === "Kissa" || auth === "authenticated") {
    localStorage.setItem("auth", "authenticated");
    return <Masterclass />;
  }
  return <Password />;
}

export default App;
