const episodes = [
  {
    url: "https://www.youtube.com/embed/QgwQB5a8920",
    title: "Video 1",
    heading: "Intro",
    desc: "Tervetuloa Jurekin mestarikurssille musiikintuotannosta.",
    length: "03:50",
  },
  {
    url: "https://www.youtube.com/embed/QgwQB5a8920",
    title: "Video 1",
    heading: "Sisältö",
    desc: "Tällä kurssillä käydään läpi vaikka ja mitä",
    length: "03:50",
  },
  {
    url: "https://www.youtube.com/embed/QgwQB5a8920",
    title: "Video 1",
    heading: "Jurekin matka",
    desc: "Kuinka Jurekista tuli yksi Suomen tunnetuimmista tuottajista",
    length: "03:50",
  },
  {
    url: "https://www.youtube.com/embed/QgwQB5a8920",
    title: "Video 1",
    heading: "Inspiraatio",
    desc: "Miten inspiraatiota ruokitaan",
    length: "03:50",
  },
  {
    url: "https://www.youtube.com/embed/QgwQB5a8920",
    title: "Video 1",
    heading: "Keinutaan (Antti Tuisku) tuotanto 1",
    desc: "",
    length: "03:50",
  },
  {
    url: "https://www.youtube.com/embed/QgwQB5a8920",
    title: "Video 1",
    heading: "Keinutaan (Antti Tuisku) tuotanto 2",
    desc: "",
    length: "03:50",
  },
  {
    url: "https://www.youtube.com/embed/QgwQB5a8920",
    title: "Video 2",
    heading: "Turvasana (Jenni Vartiainen) tuotanto 1",
    desc: "",
    length: "03:50",
  },
];

export default episodes;
