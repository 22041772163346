import React from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import "./App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'

class Episode extends React.Component {
  render() {
    return (
      <>
        <Container fluid className="EpContainer">
          <Row>
            <Col xs="12" sm="12" md="6" lg="6" xl="6">
              <iframe
                title={this.props.title}
                width="100%"
                height="300px"
                src={this.props.url}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Col>
            <Col>
            <h4 id={this.props.nro}>{this.props.heading} ({this.props.length})</h4>
              <p className="mb-5">{this.props.desc}</p>

              <div className="BackToTop">
              <span><a href="#top">Takaisin kurssisisältöön  <FontAwesomeIcon icon={faChevronCircleUp} size="lg" /></a></span>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Episode;
