import React from "react";
import Container from "react-bootstrap/Container";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSkull } from "@fortawesome/free-solid-svg-icons";
import { Row } from "react-bootstrap";

class Episode extends React.Component {
  render() {
    return (
      <>
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center min-vh-100">
          <Container>
            <Row className="justify-content-center m-4"><FontAwesomeIcon icon={faSkull} size="lg"/></Row>
            <Row className="justify-content-center m-4"><p>Pitäisikö sinun olla täällä?</p></Row>
            <Row className="justify-content-center m-4"><p>Jos pitäisi, niin laita mailia sasu@hookfinland.fi</p></Row>
            </Container>
          </div>
        </Container>
      </>
    );
  }
}

export default Episode;
